<template>
  <div class="wrap">
    <div>
      <div class="bread">
        <img
          class="add"
          src="@/assets/address.png"
        >
        <span>当前位置：</span>
        <router-link to="/">首页</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link :to="{name: 'About', params: {type: 'qyjj'}}">关于棠溪</router-link>
        <img
          class="right"
          src="@/assets/breadr.png"
        >
        <router-link :to="{name: 'About', params: {type: $route.params.type}}">{{$route.params.type | breadTitle}}</router-link>
      </div>
      <div class="about-wrap">
        <div class="tab-wrap">
          <div class="tab">
            <router-link
              :class="{active: 'qyjj' === $route.params.type}"
              :to="{name: 'About', params: {type: 'qyjj'}}"
            >企业简介</router-link>
            <router-link
              :class="{active: 'fzlc' === $route.params.type}"
              :to="{name: 'About', params: {type: 'fzlc'}}"
            >发展历程</router-link>
            <router-link
              :class="{active: 'qywh' === $route.params.type}"
              :to="{name: 'About', params: {type: 'qywh'}}"
            >企业文化</router-link>
            <router-link
              :class="{active: 'zzry' === $route.params.type}"
              :to="{name: 'About', params: {type: 'zzry'}}"
            >资质荣誉</router-link>
            <router-link
              :class="{active: 'dsfc' === $route.params.type}"
              :to="{name: 'About', params: {type: 'dsfc'}}"
            >大师风采</router-link>
            <router-link
              :class="{active: 'dzgy' === $route.params.type}"
              :to="{name: 'About', params: {type: 'dzgy'}}"
            >锻造工艺</router-link>
          </div>
        </div>
        <div class="content">
          <div :is="$route.params.type"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qyjj from './components/qyjj.vue'
import qywh from './components/qywh.vue'
import fzlc from './components/fzlc.vue'
import dsfc from './components/dsfc.vue'
import zzry from './components/zzry.vue'
import dzgy from './components/dzgy.vue'
export default {
  name: 'About',
  filters: {
    breadTitle(route) {
      const obj = {
        qyjj: '企业简介',
        fzlc: '发展历程',
        qywh: '企业文化',
        zzry: '资质荣誉',
        dsfc: '大师风采',
        dzgy: '锻造工艺',
      }
      return obj[route]
    },
  },
  components: {
    qyjj,
    qywh,
    fzlc,
    dsfc,
    zzry,
    dzgy
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .wrap {
    // min-width: 1760px;
    background: #f2f2f2;
    overflow: hidden;
    > div {
      // width: 1760px;
      margin: 0 auto;
      padding: 0 20px;
      .about-wrap {
        background: #fff;
        padding: 25px 0;
        > .tab-wrap {
          > .tab {
            text-align: center;
            border-bottom: 1px solid #d8d8d8;
            padding-bottom: 25px;
            margin: 0 32px;
          }
        }

        > .content {
          padding: 50px 0;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .wrap {
    background: #f2f2f2;
    > div {
      .about-wrap {
        background: #fff;
        padding: 14px 0;
        .tab-wrap {
          padding: 0 15px;
        }
        > .content {
          padding: 20px 0;
        }
      }
    }
  }
}
</style>