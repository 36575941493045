<template>
  <div>
    <div class="pc">
      <img src="@/assets/qywh_1.png">
      <img src="@/assets/qywh_2.png">
      <img src="@/assets/qywh_3.png">
      <img src="@/assets/qywh_4.png">
      <img src="@/assets/qywh_5.png">
      <img src="@/assets/qywh_6.png">
      <img src="@/assets/qywh_7.png">
    </div>
    <div class="m">
      <img src="@/assets/m_qywh_1.png">
      <img src="@/assets/m_qywh_2.png">
      <img src="@/assets/m_qywh_3.png">
      <img src="@/assets/m_qywh_4.png">
      <img src="@/assets/m_qywh_5.png">
      <img src="@/assets/m_qywh_6.png">
      <img src="@/assets/m_qywh_7.png">
    </div>

  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
img {
  max-width: 100%;
}
@media screen and (min-width: 750px) {
  .m {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .pc {
    display: none;
  }
}
</style>