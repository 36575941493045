<template>
  <div class="dsfc-wrap">
    <div class="ds-img">
      <img
        class="pc"
        src="@/assets/dsfc1.png"
      >
      <img
        class="m"
        src="@/assets/dsfcm1.png"
      >
    </div>
    <div class="ds-text">
      <p>棠溪宝剑传人高庆民，西平县棠溪文武学校校长、中国民间工艺美术大师、河南棠溪宝剑集团有限公司董事长、全国五一劳动奖章获得者、高级工程师、河南省人大代表、驻马店市人大常委，西平县人大副主任。</p>
      <p>高庆民与其毕业于天津大学的父亲高锡坤两代人，历经半个多世纪，用全部精力和心血，成功地挖掘、恢复、研制和生产出了位于历史九大名剑之首的棠溪宝剑，焊接了其曾经在中国历史上辉煌了2000多年的历史断层；并以不断创新的精神，把棠溪宝剑优秀的传统工艺不断发扬光大，继承发展了中国剑文化，从而把棠溪宝剑和中国冶铁铸剑工艺推向极致。 高庆民组织铸造的澳门回归剑、香港回归剑、国威剑、法威剑、中华第一剑、轩辕乾坤剑、奥运宝剑、千年龙剑等巨型剑先后被澳门特别行政区、香港特别行政区、中国历史博物馆、中国体育博物馆、皇帝故里新郑、2008北京奥组委、中国收藏家协会、河南省博物馆等权威单位永久性收藏。</p>
    </div>
    <div class="ds-info-imgs">
      <div class="pc">
        <div>
          <img
            class="pc"
            src="@/assets/dsfc2.png"
          >
          <img
            class="pc"
            src="@/assets/dsfc3.png"
          >
        </div>
        <img
          class="pc"
          src="@/assets/dsfc4.png"
        >
      </div>
      <img
        class="m"
        src="@/assets/dsfc_m.png"
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .dsfc-wrap {
    padding-bottom: 60px;
    > .ds-img {
      > .m {
        display: none;
      }
      > img {
        width: 100%;
      }
    }
    > .ds-text {
      margin-top: 31px;
      padding: 0 114px;
      > p {
        font-size: 20px;
        color: #333333;
        line-height: 48px;
        text-indent: 40px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    > .ds-info-imgs {
      margin-top: 60px;
      padding: 0 114px;
      > .m {
        display: none;
      }
      > .pc {
        > div {
          overflow: hidden;
          margin-bottom: 20px;
          > img:nth-child(1) {
            float: left;
          }
          > img:nth-child(2) {
            float: right;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .dsfc-wrap {
    padding: 20px 15px;
    > .ds-img {
      > .pc {
        display: none;
      }
      > img {
        display: block;
        padding: 0 15px;
        box-sizing: border-box;
        width: 100%;
      }
    }
    > .ds-text {
      margin-top: 21px;
      > p {
        font-size: 13px;
        color: #333333;
        line-height: 26px;
        text-indent: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    > .ds-info-imgs {
      margin-top: 16px;
      > .pc {
        display: none;
      }
      > .m {
        width: 100%;
      }
    }
  }
}
</style>