<template>
  <div class="qyjj-wrap">
    <div class="jtjj">
      <img src="@/assets/jtjj_bg.png">
      <div class="right">
        <div class="con">
          <img
            class="pc"
            src="@/assets/jtjj_title.png"
          >
          <img
            class="m"
            src="@/assets/jtjj_mtitle.png"
          >
          <div class="text">
            <p>河南棠溪宝剑集团有限公司是一家知名文化企业，公司以“传承传统工艺，挖掘宝剑文化，弘扬民族精神”为己任，立足艺术品、礼品、收藏品等文化领域，成为集创意研发生产销售宝剑、宝刀、玉器加工为主导，兼营文化、旅游生态开发等综合性集团公司。</p>
            <p>河南棠溪宝剑集团有限公司是国家级工业旅游示范点、全国创名牌企业、中国科技型企业、河南省文化重点保护企业、河南省文化企业、中国冶铁铸剑文化研究基地，河南省文物复仿制品开发基地。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zyjy">
      <div class="top">
        <div class="con">
          <img
            class="pc"
            src="@/assets/zyjy_title.png"
          >
          <img
            class="m"
            src="@/assets/zyjy_mtitle.png"
          >
          <p>公司主要生产销售棠溪牌系列宝剑：中华第一剑、棠溪古剑、九龙剑、将军剑、成功剑、奥运剑、轩辕乾坤剑等</p>
        </div>
        <img src="@/assets/zyjy1.png">
      </div>
      <img src="@/assets/zyjy2.png">
    </div>
    <div class="jthj">
      <div class="top">
        <img
          class="pc"
          src="@/assets/jthj1.png"
        >
        <img
          class="m"
          src="@/assets/jthjm1.png"
        >
        <p>棠溪宝剑集团铸剑坊环境优美，棠溪牌系列宝剑：中华第一剑、棠溪古剑、九龙剑、将军剑、成功剑、奥运剑、轩辕乾坤剑等均打造于此。</p>
      </div>
      <div class="bottom clearfix">
        <img src="@/assets/jthj2.png">
        <div>
          <img src="@/assets/jthj3.png">
          <p>棠溪宝剑集团环境优美、景色宜人，流水碧绿清澈，壁挂集团各种宝剑展示介绍、锻造工艺、荣誉展示、企业文化等，展现集团深厚的文化。</p>
        </div>
      </div>
    </div>
    <div class="logosy">
      <p>棠溪LOGO集中体现了深刻的棠溪剑文化。主色调为红色，行政红色，也象征吉祥、喜气、热烈、奔放、激情、斗志，而中国传统文化中，无形之首的火也是红色，八卦中的离卦也是红色，因此，LOGO与公司的产品取向——中国传统文化的挖掘和恢复是相对应的。</p>
      <img src="@/assets/logosy.png">
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .jtjj {
    display: flex;
    > img {
      height: 761px;
    }
    > .right {
      display: inline-block;
      flex: 1;
      position: relative;
      > .con {
        position: absolute;
        left: -108px;
        right: 0;
        background: #ffffff;
        box-shadow: 22px 22px 37px 0px rgba(0, 0, 0, 0.05);
        top: 50%;
        transform: translateY(-50%);
        > img {
          height: 186px;
          position: absolute;
          top: -62px;
        }
        > .m {
          display: none;
        }
        > .text {
          margin: 167px 40px 103px;
          > p {
            font-size: 20px;
            color: #333333;
            line-height: 48px;
            text-indent: 40px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
        }
      }
    }
  }
  .zyjy {
    padding: 0 114px;
    margin-top: 106px;
    > .top {
      position: relative;
      > .con {
        width: 836px;
        > img {
          height: 182px;
        }
        > .m {
          display: none;
        }
        > p {
          margin-top: 33px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          line-height: 48px;
          text-indent: 40px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      > img {
        position: absolute;
        right: 0;
        height: 508px;
        top: -57px;
      }
    }
    > img {
      margin-top: 72px;
      width: 1356px;
    }
  }
  .jthj {
    padding: 0 114px;
    margin-top: 51px;
    > .top {
      background: url('~@/assets/jthj_pctitle.png') no-repeat;
      background-size: 1053px auto;
      display: flex;
      align-items: center;
      padding-top: 212px;
      > img {
        height: 613px;
      }
      > .m {
        display: none;
      }
      > p {
        flex: 1;
        font-size: 20px;
        color: #333333;
        line-height: 48px;
        text-indent: 40px;
        padding-left: 41px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    > .bottom {
      margin-top: 31px;
      > img {
        float: left;
        height: 723px;
      }
      > div {
        width: 928px;
        float: left;
        margin-left: 35px;
        position: relative;
        > img {
          width: 100%;
          position: absolute;
          top: -158px;
        }
        > p {
          font-size: 20px;
          color: #333333;
          line-height: 48px;
          text-indent: 40px;
          padding-top: 475px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  }
  .logosy {
    padding: 0 114px;
    margin-top: 124px;
    background: url('~@/assets/logosy_title.png') no-repeat;
    background-size: auto 183px;
    background-position: 114px 0;
    padding-top: 183px;
    display: flex;
    > p {
      flex: 1;
      font-size: 20px;
      color: #333333;
      line-height: 48px;
      text-indent: 40px;
      padding: 32px 27px 0 15px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    > img {
      height: 508px;
      margin-top: -237px;
    }
  }
}
@media screen and (max-width: 750px) {
  .qyjj-wrap {
    padding: 0 15px;
  }
  .jtjj {
    > img {
      width: 100%;
    }
    > .right {
      > .con {
        background: #ffffff;
        box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.05);
        padding: 0 12px 12px;
        overflow: hidden;
        > img {
          width: 88%;
          margin-top: -0.5%;
        }
        > .pc {
          display: none;
        }
        > .text {
          margin-top: 6px;
          > p {
            font-size: 12px;
            color: #333333;
            line-height: 24px;
            text-indent: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
          }
        }
      }
    }
  }
  .zyjy {
    > .top {
      position: relative;
      > .con {
        > img {
          width: 92%;
        }
        > .pc {
          display: none;
        }
        > p {
          font-size: 12px;
          color: #333333;
          line-height: 24px;
          text-indent: 24px;
          box-sizing: border-box;
          width: 58%;
          padding: 3px 10px 0;
          height: 80px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      > img {
        width: 42%;
        position: absolute;
        right: 0;
        top: 60%;
      }
    }
    > img {
      width: 90%;
      margin-top: 13%;
    }
  }
  .jthj {
    > .top {
      background: url('~@/assets/jthj_mtitle.png') no-repeat;
      background-size: 100% auto;
      padding-top: 20%;
      display: flex;
      > img {
        width: 65%;
      }
      > .pc {
        display: none;
      }
      > p {
        flex: 1;
        padding: 0 14px 0 9px;
        font-size: 12px;
        color: #333333;
        line-height: 24px;
        text-indent: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
      }
    }
    > .bottom {
      > img {
        float: left;
        width: 37%;
      }
      > div {
        width: 60%;
        float: right;
        > img {
          width: 100%;
          margin-top: -12%;
        }
        > p {
          font-size: 12px;
          color: #333333;
          line-height: 24px;
          text-indent: 24px;
          margin-top: 4px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
    }
  }
  .logosy {
    margin-top: 20px;
    background: url('~@/assets/logosy_mtitle.png') no-repeat;
    background-size: 100% auto;
    padding-top: 15%;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    flex-direction: column;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;

    > p {
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      text-indent: 24px;
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
      margin-top: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
    }
    > img {
      width: 71%;
      margin: 0 auto;
      display: block;
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      order: 1;
    }
  }
}
</style>