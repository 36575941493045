<template>
  <div class="zzry-wrap">
    <ul>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_1.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m1.png"
        >
        <p>国家级非遗</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_2.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m2.png"
        >
        <p>国家地理标志</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_3.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m3.png"
        >
        <p>国家级旅游示范点</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_4.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m4.png"
        >
        <p>特批“第一”商标</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_5.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m5.png"
        >
        <p>文化研究基地</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_6.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m6.png"
        >
        <p>河南老字号</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_7.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m7.png"
        >
        <p>研究开发基地</p>
      </li>
      <li>
        <img
          class="pc"
          src="@/assets/zzry_8.png"
        >
        <img
          class="m"
          src="@/assets/zzry_m8.png"
        >
        <p>示范展示馆</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .zzry-wrap {
    padding: 0 32px;
    > ul {
      display: flex;
      flex-wrap: wrap;
      > li {
        width: 25%;
        text-align: center;
        margin-bottom: 48px;
        > img.m {
          display: none;
        }
        > img {
          height: 280px;
        }
        > p {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          margin-top: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
      }
      > li:nth-child(4n),
      li:nth-child(4n-1),
      li:nth-child(4n-2) {
        > img {
          position: relative;
          left: -10px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .zzry-wrap {
    padding: 0 15px;
    > ul {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      > li {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        > img.pc {
          display: none;
        }
        > img {
          width: 103%;
          flex: 1;
        }
        > p {
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          margin-top: 11px;
          width: 100%;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
      > li:nth-child(2n) {
        align-items: end;
      }
    }
  }
}
</style>