<template>
  <div class="fzlc-wrap">
    <template v-for="i in list">
      <div class="item" :key="i.id">
        <div class="time">
          <p v-if="i.year.length>1">{{i.year[0]}}年<br />{{i.year[1]}}月</p>
          <p v-else>{{i.year[0]}}<br />年</p>
        </div>
        <div class="con">
          <p>{{i.title}}</p>
          <img v-if="i.image" :src="i.image" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { getHistory } from "@/api";

export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    getHistory().then((res) => {
      if (res.data.code) {
        this.list = res.data.data;
      }
    });
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 750px) {
  .fzlc-wrap {
    padding: 182px 71px 100px;
    > .item {
      margin-bottom: 24px;
      position: relative;
      > .time {
        width: 160px;
        height: 160px;
        background: #f8efe3;
        margin: 0 auto 90px;
        position: relative;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > p {
          width: 100%;
          font-size: 40px;
          font-weight: 900;
          color: #c8a475;
          line-height: 48px;
          text-align: center;
          font-family: STSongti-SC-Black, STSongti-SC;
        }
      }
      > .time::after {
        display: block;
        content: "";
        width: 3px;
        background: rgba(200, 164, 117, 0.3);
        height: 40px;
        position: absolute;
        top: 100%;
        margin-top: 24px;
      }
      > .con {
        width: 511px;
        padding: 25px;
        box-sizing: border-box;
        background: #f2f2f2;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        > p {
          font-size: 24px;
          color: #333333;
          line-height: 33px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > img {
          width: 100%;
          margin-top: 12px;
        }
      }
      > .con::after {
        display: block;
        content: "";
        position: absolute;
        width: 170px;
        height: 3px;
        background: rgba(200, 164, 117, 0.3);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    > .item:nth-last-child(1) {
      margin-bottom: 0;
      > .time::after {
        display: none;
      }
    }
    > .item:nth-child(2n-1) {
      > .con {
        right: 0;
      }
      > .con::after {
        right: 100%;
        margin-right: 24px;
      }
    }
    > .item:nth-child(2n) {
      > .con {
        left: 0;
      }
      > .con::after {
        left: 100%;
        margin-left: 24px;
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .fzlc-wrap {
    padding: 0 15px;
    > .item {
      margin-bottom: 30px;
      > .time {
        width: 62px;
        height: 62px;
        background: #f8efe3;
        margin: 0 auto 30px;
        position: relative;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > p {
          width: 100%;
          font-size: 16px;
          font-weight: 900;
          color: #c8a475;
          line-height: 19px;
          text-align: center;
          font-family: STSongti-SC-Black, STSongti-SC;
        }
      }
      > .time::after {
        display: block;
        content: "";
        width: 1px;
        background: rgba(200, 164, 117, 0.3);
        height: 14px;
        position: absolute;
        top: 100%;
        margin-top: 8px;
      }
      > .con {
        padding: 17px;
        background: #f2f2f2;
        border-radius: 6px;
        position: relative;
        > p {
          font-size: 13px;
          color: #333333;
          line-height: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        > img {
          width: 100%;
          margin-top: 10px;
        }
      }
      > .con::after {
        display: block;
        content: "";
        width: 1px;
        background: rgba(200, 164, 117, 0.3);
        height: 14px;
        position: absolute;
        top: 100%;
        margin-top: 8px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    > .item:nth-last-child(1) {
      margin-bottom: 0;
      > .con::after {
        display: none;
      }
    }
  }
}
</style>