<template>
  <div class="dzgy-wrap">
    <div class="pc">
      <img v-for="item in imgs" :key="item + 'pc'" :src="item">
      <!-- <img src="@/assets/dzgy_2.png">
      <img src="@/assets/dzgy_3.png">
      <img src="@/assets/dzgy_4.png">
      <img src="@/assets/dzgy_5.png">
      <img src="@/assets/dzgy_6.png"> -->
    </div>
    <div class="m">
      <img v-for="item in imgs" :key="item + 'pc'" :src="item">
      <!-- <img src="@/assets/dzgy_m1.png">
      <img src="@/assets/dzgy_m2.png">
      <img src="@/assets/dzgy_m3.png">
      <img src="@/assets/dzgy_m4.png">
      <img src="@/assets/dzgy_m5.png">
      <img src="@/assets/dzgy_m6.png"> -->
    </div>
  </div>
</template>

<script>
import { getImg } from '@/api'
export default {
  data(){
    return{
      imgs: []
    }
  },
  created(){
      getImg({type:'forging'})
      .then((res) => {
        console.log(res)
        if(res.data.code) {
          this.imgs = res.data.data.filter(item => item.id === 20)[0].images
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
}
</script>

<style lang="less" scoped>
img {
  width: 100%;
}
@media screen and (min-width: 750px) {
  .dzgy-wrap {
    padding: 0 32px;
    > .pc {
      > img {
        margin-bottom: 34px;
      }
    }
    > .m {
      display: none;
    }
  }
}
@media screen and (max-width: 750px) {
  .dzgy-wrap {
    padding: 0 15px;
    > .m {
      > img {
        margin-bottom: 10px;
      }
    }
    > .pc {
      display: none;
    }
  }
}
</style>